export default [
  {
    id: 1,
    title: "Demetra Luxenia Suite, Athens",
    description: "2 visitors, 1 bed / bathroom",
    coverImg: [
      "demetra1.png",
      "demetra2.png",
      "demetra3.png",
      "demetra4.png",
      "demetra5.png",
      "demetra6.png",
      "demetra7.png",
      "demetra8.png",
      "demetra9.png",
    ],
    moreInfoUrl:
      "https://www.airbnb.gr/rooms/845661984263062710?adults=1&checkin=&checkout=&children=0&infants=0&pets=0&wishlist_item_id=11002928435288&source_impression_id=p3_1713860922_ImzkpGAYn534zrhi&previous_page_section_name=1000",
  },
  {
    id: 2,
    title: "Hestia Luxenia Suite, Athens",
    description: "2 visitors, 1 double-bed / bathroom",
    coverImg: [
      "hestia1.png",
      "hestia2.png",
      "hestia3.png",
      "hestia4.png",
      "hestia5.png",
      "hestia6.png",
      "hestia7.png",
    ],
    moreInfoUrl:
      "https://www.airbnb.gr/rooms/817290459558385735?adults=1&children=0&infants=0&pets=0&wishlist_item_id=11002765137087&check_in=2024-05-04&check_out=2024-05-05&source_impression_id=p3_1713860922_4BQUcxuaFPyjhhEg&previous_page_section_name=1000",
  },
  {
    id: 3,
    title: "Artemis Luxenia Suite, Athens",
    description: "2 visitors, 1 bed / bathroom",
    coverImg: [
      "artemis1.png",
      "artemis2.png",
      "artemis3.png",
      "artemis4.png",
      "artemis5.png",
      "artemis6.png",
    ],
    moreInfoUrl:
      "https://www.airbnb.gr/rooms/817239839811021747?adults=1&checkin=&checkout=&children=0&infants=0&pets=0&wishlist_item_id=11002050633447&source_impression_id=p3_1713860922_FKcCVZ7%2FVMGazCmB&previous_page_section_name=1000",
  },
  {
    id: 4,
    title: "Zues Luxenia Suite, Athens",
    description: "4 visitors, 2 bedrooms / queensize beds, 2 bathrooms",
    coverImg: [
      "dia1.png",
      "dia2.png",
      "dia3.png",
      "dia4.png",
      "dia5.png",
      "dia6.png",
      "dia7.png",
    ],
    moreInfoUrl:
      "https://www.airbnb.gr/rooms/817199469392886680?adults=1&checkin=&checkout=&children=0&infants=0&pets=0&wishlist_item_id=11002050633196&source_impression_id=p3_1713860922_BYjrcZJb4JWpH%2Fay&previous_page_section_name=1000",
  },
  {
    id: 5,
    title: "Aphrodite Luxenia Suite, Athens",
    description: "4 visitors, 2 bedrooms / queensize beds, 2 bathrooms",
    coverImg: [
      "afro1.png",
      "afro2.png",
      "afro3.png",
      "afro4.png",
      "afro5.png",
      "afro6.png",
      "afro7.png",
      "afro10.png",
      "afro8.png",
      "afro9.png",
    ],
    moreInfoUrl:
      "https://www.airbnb.gr/rooms/817241888025145679?adults=1&children=0&infants=0&pets=0&wishlist_item_id=11002050633063&check_in=2024-05-14&check_out=2024-05-15&source_impression_id=p3_1713860922_OaYPM9KH5zaRxB4g&previous_page_section_name=1000",
  },
  {
    id: 6,
    title: "Hera Luxenia Suite, Athens",
    description: "2 visitors, 1 bed / bathroom",
    coverImg: [
      "hera1.png",
      "hera2.png",
      "hera3.png",
      "hera4.png",
      "hera6.png",
      "hera7.png",
      "hera8.png",
      "hera9.png",
    ],
    moreInfoUrl:
      "https://www.airbnb.gr/rooms/817278055163832422?adults=1&checkin=&checkout=&children=0&infants=0&pets=0&wishlist_item_id=11002050632918&source_impression_id=p3_1713860922_1ODNCtH32%2B%2Be%2F%2FBL&previous_page_section_name=1000",
  },
];
