import React from "react";
import "./betweenap.css";

const Betweenap = () => {
  return (
    <div className="between_container">
      <h1 className="h1_between">Discover comfort, a peaceful stay</h1>
      <p className="p_between">
        Even if you are traveling for leisure or business, for a few days or for
        a while, there is always a place that you can call home. Luxenia
        apartments are designed around sustainable-driven and eco-friendly
        principles and each one of them boasts energy-efficient appliances,
        including in-unit washers and dryers. Our team makes sure that when you
        stay in one of our apartments you have a guaranteed quality of service
        24/7.
      </p>
      <h2 className="h2_between">
        {" "}
        <i className="fa-regular fa-star"> </i> 4.95
      </h2>
      <p className="p2_between">With more than 498 reviews!</p>
    </div>
  );
};

export default Betweenap;
