import React from "react";
import "./header.css";

const header = () => {
  return (
    <div>
      <div className="background-header">
        Προς το παρόν όλα τα καταλυματά μας είναι μη διαθέσιμα!
      </div>
    </div>
  );
};

export default header;
