export default [
  {
    id: 2,
    circle: "",
    number: "02",
    title: "Public transport steps away ",
    paragraph:
      "Easily connect to the metro, bus, or tram within a 3-minute walk and uncover the vibrant sights of Athens!",
    coverImg: ["explore2.jpg"],
  },

  {
    id: 3,
    circle: "",
    number: "03",
    title: "Self Check-in ",
    paragraph:
      "Complete your check-in fast and easy in all of our apartments simply by using your phone",
    coverImg: ["transport.jpg"],
  },

  {
    id: 4,
    circle: "",
    number: "04",
    title: "High-touch Service ",
    paragraph:
      "Reliable support and a consistent, quality experience in every Luxenia apartment",
    coverImg: ["selfcheckin.png"],
  },
  {
    id: 1,
    circle: "",
    number: "01",
    title: "Excellent Location ",
    paragraph:
      "More than 95% of our annual customers have rated the our location with 5 stars",
    coverImg: ["service.png"],
  },
];
