import React from "react";
import "./contact.css";

const Contact = () => {
  return (
    <section className="contact_section" id="contact">
      <h1 className="h1_contact">More about us!</h1>
      <div className="wrapper">
        <div className="left_content">
          <a
            className="host_anch"
            href="https://www.airbnb.com/users/show/119894928?_set_bev_on_new_domain=1709937378_YzRiYzAwYzQ4NzY1"
            target="_blank"
          >
            {" "}
            <div className="host_box">
              <div className="host_info">
                <img className="info_img " src="/assets/logo.png" />
                <h2 className="info_h2">Ioannis</h2>
                <p className="info_p">
                  Superhost{" "}
                  <span className="info_icon">
                    <ion-icon name="medal"></ion-icon>
                  </span>
                </p>
                <div className="info_ratings">
                  <h4 className="reviews_count">498</h4>
                  <p className="reviews_p">Reviews</p>
                  <hr className="contact_line"></hr>
                  <h4 className="rating_count">4,96</h4>
                  <p className="rating_p">Ratings</p>
                  <hr className="contact_line"></hr>
                  <h4 className="years_count">1</h4>
                  <p className="years_p">Years</p>
                </div>
              </div>
            </div>
          </a>
          <div className="below_host_box">
            <p className="below_info">
              <span>
                <ion-icon name="bag-handle-outline"></ion-icon>
              </span>{" "}
              My Job: The Luxenia Apartments{" "}
            </p>
            <p className="below_info">
              <span>
                <ion-icon name="language-outline"></ion-icon>
              </span>{" "}
              Languages: English{" "}
            </p>
            <p className="below_info">
              <span>
                <ion-icon name="business-outline"></ion-icon>
              </span>{" "}
              Business{" "}
            </p>

            <p className="luxenia_info">
              Hello! We are the Luxenia Apartments Group and we will be very
              happy to host you at...
            </p>
            <a
              className="more"
              href="https://www.airbnb.com/users/show/119894928"
              target="_blank"
            >
              More{" "}
              <span className="more_icon">
                <ion-icon name="chevron-forward-outline"></ion-icon>
              </span>
            </a>
          </div>
        </div>
        <div className="right_content">
          <h1 className="right_h1">
            {" "}
            Ioannis is a Superhost{" "}
            <span className="info_icon">
              <ion-icon name="medal"></ion-icon>
            </span>
          </h1>
          <p className="right_p">
            Superhosts are experienced, highly rated hosts who are dedicated to
            providing great stays for guests.
          </p>
          <h2 className="right_h2">Co-hosts</h2>
          <img className="right-img2" src="/assets/emil2.png" />{" "}
          <a
            className="emil2"
            href="https://www.airbnb.com/users/show/559198612"
            target="_blank"
          >
            {" "}
            Filitsa And Christos{" "}
          </a>
          <h3 className="right_h2">Host details</h3>
          <p className="right_p">Response rate: 100% </p>
          <p className="estimated_response">
            Estimated reply time within an hour
          </p>
          <p className="estimated_response">
            <ion-icon name="phone-portrait-outline"></ion-icon> +30 6942557565
          </p>
          <a className="hero_a" href="mailto:info@luxenia.gr" target="_blank">
            <button className="contact_btn"> Send us a Message</button>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Contact;
