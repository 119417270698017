import React from "react";
import "./hero.css";

const Hero = () => {
  return (
    <section className="section_hero" id="home">
      <div className="hero1">
        <div className="hero1_containers">
          <h1 className="hero_text">Comfort Stay,</h1>
          <h2 className="hero_text">but better.</h2>

          <p className="hero_p">
            Feel like home in one of our apartments, located in the very heart
            of Athens city. Walk on the ancient paths along with comforting
            views and Greek tradition, all just steps away.
          </p>
          <div>
            <a
              className="hero_a"
              href="https://www.airbnb.gr/wishlists/v/327265668?viralityEntryPoint=49&s=76"
              target="_blank"
            >
              <button
                className="hero_btn"
                href="https://www.airbnb.gr/wishlists/v/327265668?viralityEntryPoint=49&s=76"
                target="_blank"
              >
                {" "}
                View Apartments
              </button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
