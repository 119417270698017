import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <footer className="footer_wrapper">
      <div className="flex_items">
        <div className="left1_content">
          <h1 className="h1_left">About</h1>
          <p className="p1_footer">
            Feel like home in one of our apartments, located in the very heart
            of Athens city. Walk on the ancient paths along with comforting
            views and greek tradition, all just steps away. Find your peace of
            mind, flexibility, and confidence to embrace your next adventure in
            one of the most iconic neighbourhoods of Athens city. Under the
            momentous gaze of the Acropolis our apartments offer the ultimate
            experience of luxury, along with tradition and history.{" "}
          </p>
        </div>
        <div className="middle_content">
          <h1 className="h1_middle">Quick Links</h1>
          <ul className="ul_middle">
            <li>
              {" "}
              <a className="footer_menu" href="#home">
                {" "}
                Home{" "}
              </a>
            </li>
            <li>
              {" "}
              <a className="footer_menu" href="#about">
                {" "}
                About{" "}
              </a>
            </li>
            <li>
              {" "}
              <a className="footer_menu" href="#apartments">
                {" "}
                Apartments
              </a>
            </li>
            <li>
              {" "}
              <a className="footer_menu" href="#testimonials">
                {" "}
                Testimonials
              </a>
            </li>
            <li>
              <a className="footer_menu" href="#contact">
                {" "}
                Contact{" "}
              </a>
            </li>
          </ul>
        </div>
        <div className="right1_content">
          <h1 className="h1_right">Apartments</h1>
          <ul className="ul_right">
            <li>
              <a
                className="footer_menu"
                target="_blank"
                href="https://www.airbnb.gr/rooms/845661984263062710?adults=1&checkin=&checkout=&children=0&infants=0&pets=0&wishlist_item_id=11002928435288&source_impression_id=p3_1713514680_N2JDWbkCwc8FwOdU&previous_page_section_name=1000"
              >
                {" "}
                Demetra{" "}
              </a>
            </li>
            <li>
              {" "}
              <a
                className="footer_menu"
                target="_blank"
                href="https://www.airbnb.gr/rooms/817290459558385735?adults=1&checkin=&checkout=&children=0&infants=0&pets=0&wishlist_item_id=11002765137087&source_impression_id=p3_1713514680_1ovbNz%2FGCuEfQYsZ&previous_page_section_name=1000"
              >
                {" "}
                Hestia{" "}
              </a>
            </li>
            <li>
              {" "}
              <a
                className="footer_menu"
                target="_blank"
                href="https://www.airbnb.gr/rooms/817239839811021747?adults=1&checkin=&checkout=&children=0&infants=0&pets=0&wishlist_item_id=11002050633447&source_impression_id=p3_1713514680_zh43zrWsKoCFI3jl&previous_page_section_name=1000"
              >
                {" "}
                Artemis{" "}
              </a>
            </li>
            <li>
              {" "}
              <a
                className="footer_menu"
                target="_blank"
                href="https://www.airbnb.gr/rooms/817199469392886680?adults=1&checkin=&checkout=&children=0&infants=0&pets=0&wishlist_item_id=11002050633196&source_impression_id=p3_1713514680_hd%2Brk2pfotP2AiVx&previous_page_section_name=1000"
              >
                {" "}
                Zues{" "}
              </a>
            </li>
            <li>
              <a
                className="footer_menu"
                target="_blank"
                href="https://www.airbnb.gr/rooms/817241888025145679?adults=1&children=0&infants=0&pets=0&wishlist_item_id=11002050633063&check_in=2024-05-14&check_out=2024-05-15&source_impression_id=p3_1713514680_0cXbCi0TqqYma1A%2F&previous_page_section_name=1000"
              >
                {" "}
                Aphrodite{" "}
              </a>
            </li>
            <li>
              {" "}
              <a
                className="footer_menu"
                target="_blank"
                href="https://www.airbnb.gr/rooms/817278055163832422?adults=1&checkin=&checkout=&children=0&infants=0&pets=0&wishlist_item_id=11002050632918&source_impression_id=p3_1713514680_kf1klQZskvALWGo8&previous_page_section_name=1000"
              >
                {" "}
                Hera
              </a>
            </li>
          </ul>
        </div>
      </div>
      <hr className="footer_line"></hr>
      <div className="footer_copy">
        2024 &#169; Luxenia. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
