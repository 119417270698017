export default [
  {
    id: 1,
    name: "Amy",
    location: "Lancaster, Pennsylvania",
    description:
      "We enjoyed our stay at this very convenient apartment. It was wonderful to have the large covered balcony as well as an additional quiet, outdoor private space in the back bedroom. This area is very walkable and lively with many good restaurants. The hosts were responsive and allowed us to check in early. We loved our time in Athens!",
    coverImg: "test1.png",
    stayed: "For a couple of nights",
  },
  ,
  {
    id: 2,
    name: "Maria",
    location: "Danville, California",
    description:
      "The Aphrodite’s Luxiena Suites are an absolute gem in Athens! Best of all it offers a view of the Acropolis from the terrace. The hosts are incredibly responsive and attentive, ensuring a seamless stay. Plus, its prime location puts you within easy reach of all the city’s iconic sites. Highly recommend for an unforgettable Athenian experience!",
    coverImg: "test2.png",
    stayed: "For a couple of nights",
  },
  {
    id: 3,
    name: "Ram",
    location: "Israel",
    description:
      "Amazing apartment. Location is the best - close to everything and very quiet. The hosts super nice. Always available, and they were really happy for answering questions.",
    coverImg: "test3.png",
    stayed: "For a couple of nights",
  },
  {
    id: 4,
    name: "Shruthi",
    location: "Duluth, Georgia",
    description:
      "Can I give 6 stars? It was like having your own people in Greece! They were very kind, helpful and responsive. We are very glad we booked this place. This place is very close to Acropolis and all other activities we booked through Airbnb. Spotless and modern interiors large patio/balcony.",
    coverImg: "test4.png",
    stayed: "Stayed with children",
  },
  {
    id: 4,
    name: "Grant",
    location: "New York",
    description:
      "Beautiful apartment. Perfect home base. First time in Athens, we walked everywhere. Ioannis was super friendly, helpful, and quick to respond. Really appreciated the treats after the long flight.",
    coverImg: "test5.png",
    stayed: "For a couple of nights",
  },
  {
    id: 4,
    name: "Stephanie",
    location: "Eindhoven, Netherlands",
    description:
      "We had a fantastic stay. Super apartment, equipped with everything. Top location and communication was excellent. Ioannis was quick to respond and had arranged all taxis for us from the airport and port. Cannot describe our gratitude for this experience",
    coverImg: "test6.png",
    stayed: "For a week",
  },
];
